import React, {useState} from 'react';
import {Button, Error} from '../../common';
import {Http} from '../../../services';
import './forgotPassword.scss'

export default function ForgotPassword(props) {
  let email = '';
  const [errorMessage, setErrorMessage] = useState({});
  const [showError, toggleErrorDisplay] = useState(false);

  const hidePasswordReset = () => props.showPasswordReset(false);

  function handleChange(event) {
    email = event.target.value;
  }

  function handleSubmit(event) {
    event.preventDefault();
    Http.ForgotPassword('auth/password-reset/request/', {email})
      .then( (response) => {
        alert(response.data.detail)
        hidePasswordReset();
      })
      .catch( (error) => {
        setErrorMessage(error.response.data);
        toggleErrorDisplay(true);
      });
      
  }

  return <div className="component-forgot-password">
    <span className="title">Forgot password</span>
    <span className="description">Enter your email</span>
    <form onSubmit={handleSubmit}>
        <div className="fieldCell">
          <input 
              type='text' 
              name="email" 
              autoComplete="off"
              placeholder="email"
              className="form-control" 
              onChange={handleChange}/>
        </div>
        <div className="actions">
          <Button className="btn btn-primary" type="submit" text="Restore"/>
          <Button className="btn btn-danger" text="Back" onClick={hidePasswordReset}/>
        </div>
    </form>
    { showError && <Error error={errorMessage}/> }
  </div>;
}