import React from "react";
import Table from "react-bootstrap/Table";
import "./report-detail.scss";

export default function ReportDetail(props) {
  return (
    <div className="report-detail">
      <div className="report-data">
        <div>
          <p>MPAN: {props.reportDetail.mpan}</p>
        </div>
        <div>
          <p>Start date: {props.reportDetail.start_date}</p>
          <p>End date: {props.reportDetail.end_date}</p>
        </div>
        <div>
          <p>Status: {props.reportDetail.status}</p>
        </div>
      </div>

      {props.reportDetail.available_capacity &&
        props.reportDetail.available_capacity.length && (
          <Table striped bordered hover size="sm">
            <tbody>
              <tr>
                <th>Start date</th>
                <th>End date</th>
                <th>Value</th>
              </tr>
              {props.reportDetail.available_capacity.map((elem) => (
                <tr key={Date.now()}>
                  <td>{elem.start_date}</td>
                  <td>{elem.end_date}</td>
                  <td>{elem.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      {props.reportDetail.errors && (
        <div>
          {props.reportDetail.errors.map((elem) => (
            <div key={elem.error_code}>
              <div>
                <h3>{elem.error_code}</h3>
              </div>
              <div>
                <p>{elem.message}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
