import React from "react";

export default function Gmt(props) {
  function handleChange(event) {
    props.setGmt(event.target.checked);
  }

  return (
    <div className={props.className}>
      <span>GMT</span>
      <input type="checkbox" onChange={handleChange} />
    </div>
  );
}
