import React from 'react';
import {useRoutes} from 'hookrouter';
import {batchRoutes} from '../batch-routes';
import './overview.scss'

export default function Overview(props) {
  const render = useRoutes(batchRoutes);

  return <div className="component-overview">
    {render}
  </div>;
}