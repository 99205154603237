import React, {useState} from "react";
import {Button, Error} from "../../../common";
import {Http} from "../../../../services";
import {MdClear} from "react-icons/md";
import Table from "react-bootstrap/Table";
import "./list.scss";
import {navigate} from "hookrouter";

export default function List(props) {
  const showModal = () => props.showModal(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [showError, toggleErrorDisplay] = useState(false);

  const submitBath = () => {
    Http.Put(`gb-electricity/batches/${localStorage.getItem("guid")}/submit/`)
      .then(() => {
        navigate("/reports-status");
      })
      .catch(error => {
        setErrorMessage(error.response.data);
        toggleErrorDisplay(true);
      });
  };

  const deleteReport = (reportId, index) => {
    Http.Delete(
      `gb-electricity/batches/${localStorage.getItem(
        "guid"
      )}/reports/${reportId}/delete/`
    )
      .then(() => {
        props.reports.splice(index, 1);
        props.setReports([...props.reports]);
      })
      .catch(error => {
        setErrorMessage(error.response.data);
        toggleErrorDisplay(true);
      });
  };

  return (
    <div className="component-list">
      <div className="component-header">
        <span className="title">Request Report</span>
        <Button
          className="btn btn-success"
          type="submit"
          text="Create request"
          onClick={showModal}
        />
      </div>

      <div className="list">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>MPAN</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.reports.map((elem, index) => (
              <tr key={elem.reportId}>
                <td>{elem.mpan}</td>
                <td>{elem.status}</td>
                <td>
                  <MdClear
                    className="add-icon"
                    onClick={() => deleteReport(elem.reportId, index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {props.reports.length > 0 && (
        <Button
          className="btn btn-success button-submit"
          type="submit"
          text="Submit Batch"
          onClick={submitBath}
        />
      )}
      {showError && <Error error={errorMessage} />}
    </div>
  );
}
