import React from 'react';
import {ResponsiveImage} from '../common'
import {Http} from '../../services';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import './navbar.scss';

export default function Navigation(props) {
  const logOut = () => {
    Http.Logout('auth/logout/')
        .then( (response) => {
          console.log(response);
        })
        .catch( (error) => {
          console.log(error);
        });
    props.onLogin(''); 
  }

  return <Navbar bg="light">
    <div className="component-navbar">
    <Navbar.Brand><ResponsiveImage src={require(`./logo.png`)} width={300} height={100}/></Navbar.Brand>
      { props.isAuthorized ? <Nav justify defaultActiveKey="/create-batch" className="buttons">
        <Nav.Link href="/create-batch">
          Home
        </Nav.Link>
        <Nav.Link href="/" onSelect={logOut}>
          Log Out
        </Nav.Link>
      </Nav> : ''}
    </div>
    </Navbar>
}
