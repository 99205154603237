import React from "react";
import "./error.scss";

export default function Error(props) {
  const error = props.error;
  let non_field_errors = error.non_field_errors &&
    error.non_field_errors.length && (
      <div>
        {error.non_field_errors.map(elem => (
          <span key={elem}>{elem}</span>
        ))}
      </div>
    );

  let available_capacity =
    error.available_capacity &&
    error.available_capacity.length &&
    error.available_capacity.map(elem =>
      Object.keys(elem).map(key => (
        <span>
          {key} {elem[key][0]}
        </span>
      ))
    );
  return (
    <div className="component-error">
      {error.detail && <span>{error.detail}</span>}
      {error.email && <span>{error.email}</span>}
      {error.mpan &&
        error.mpan.map(elem => <span key={elem}>MPAN {elem}</span>)}
      {non_field_errors}
      {available_capacity}
    </div>
  );
}
