import React from 'react';
import {Modal} from 'react-bootstrap';
import './modal.scss'

export default function Dialog(props) {
  return <Modal show={props.show} onHide={props.handleClose} centered={true} dialogClassName="custom-dialog">
    
    <Modal.Header closeButton>
      <Modal.Title>{props.header}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.children}</Modal.Body>
  </Modal>;
}