import React from 'react';
import './create.scss'
import {FileUpload} from './fileUpload/index';
import {TypeSelection} from './typeSelection/index';

export default function Create(props) {
  
  let [typeSelected, toggleState] = React.useState(false);
  let [awsData, setAwsData] = React.useState({});

  return (<div className="component-create">
    {
      typeSelected ? <FileUpload awsData={awsData} rollBack={toggleState}/> : <TypeSelection setAwsData={setAwsData} onSelected={toggleState}/>
    }
  </div>);
}