import React, {createRef, useState} from "react";
import {navigate} from "hookrouter";
import {Button} from "../../../common";
import {AWS} from "../../../../services";
import "./fileUpload.scss";

export default function FileUpload(props) {
  const fileInput = createRef();
  const [currentFile, setFile] = useState({});
  const [disabled,setDisabled] = useState(true)

  const onChange = event => {
    if(fileInput.current.files.length){
      setFile(fileInput.current.files[0]);
      setDisabled(false);
    }
  };

  const onSubmit = event => {
    event.preventDefault();
    let data = new FormData();
    Object.entries(props.awsData).forEach(([propName, propValue]) => {
      data.append(propName, propValue);
    });
    data.append("file", currentFile);

    AWS.Post(data)
      .then(() => navigate("/reports-request"))
      .catch(error => {
        navigate("/reports-request");
      });
  };

  return (
    <div className="component-file-upload">
      <form className="upload-form" onSubmit={onSubmit}>
        <div className="custom-file">
          <input
            type="file"
            onChange={onChange}
            className="custom-file-input"
            ref={fileInput}
            id="customFile"
          />
          <label className="custom-file-label title" htmlFor="customFile">
            {currentFile.name || `Select file for uploading:`}
          </label>
        </div>
        <Button disabled={disabled} className="btn btn-primary" type="submit" text="Submit" />
      </form>
    </div>
  );
}
